@import '../../styles/functions';

main,
#root {
  height: 100%;
}

.layout {
  display: grid;
  grid-template-columns: getRem(300) 1fr getRem(360);
  grid-column-gap: 0;
  grid-row-gap: 0;
  height: calc(100% - 48px);
  width: 100%;
}

.menu {
  grid-area: 1 / 1 / 2 / 1;
  margin-top: getRem(-4);
}
.content {
  grid-area: 1 / 2 / 2 / 3;
}
.side {
  grid-area: 1 / 3 / 2 / 4;
}
