@import '../../styles/functions';

.ag-theme-alpine {
  --ag-font-size: 12px;
}

.my-icon {
  width: getRem(27);
  position: absolute;
  top: getRem(7);
  left: getRem(12);
}

.ag-root-wrapper {
  border-radius: getRem(8) getRem(8) 0 0;
}

.my-renderer {
  margin-left: getRem(30);
}

.ag-paging-panel {
  justify-content: center;
}

.ag-cell-value {
  white-space: break-spaces;
}

.ag-row {
  cursor: pointer;
}

.ag-cell {
  text-align: center;
  border-right: getRem(1) solid grey;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.loader-div {
  position: absolute;
  z-index: 10;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  border: getRem(1) solid rgb(186, 191, 199);
  width: 100rem;
  height: 45rem;
}

.grid-table {
  position: relative;
  margin-top: getRem(2);
  &-pagination {
    height: getRem(40);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: getRem(15);
    font-size: getRem(12);
    border: getRem(1) solid rgb(179, 179, 179);
    border-top: 0;
    border-radius: 0 0 getRem(8) getRem(8);
  }
}

.ag-paging-panel {
  display: none;
}

.cursor-disabled {
  cursor: no-drop;
  color: rgb(171, 171, 171);
}
