@import '../../../styles/functions';

.piece {
  // margin-left: getRem(30);
  margin-bottom: getRem(75);
  padding: 0 1rem;

  p {
    font-weight: 400;
    color: #6b7280;
    font-size: getRem(14);
  }

  &-description {
    max-width: getRem(950);
  }

  &-actions {
    // flex justify-center gap-5 mt-5 mb-6 fixed bottom-0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: getRem(20);
    width: 100%;
    left: 0;
    padding: getRem(15) 0 getRem(15);
    border-top: getRem(1) solid #6b7280;
    position: fixed;
    bottom: 0;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  &-notification {
    width: getRem(600);
    position: fixed;
  }

  &-errors {
    margin-top: getRem(16);
    list-style: inside;
    li {
      font-size: getRem(16);
      color: #e11d48;
    }
  }

  &-header {
    display: flex;
    justify-content: start;
    align-items: flex-end;
    gap: getRem(15);
    margin: 16px 0 8px;

    &-status {
      color: white;
      padding: 0 getRem(16);
      border-radius: getRem(20);
      font-size: getRem(20);
    }
  }

  &-content {
    width: 100%;
    max-width: getRem(950);

    h3 {
      font-size: getRem(15);
      margin: 0;
      line-height: normal;
    }

    p {
      font-size: getRem(14);
    }

    &-inputs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        width: 48%;

        h3 {
          font-size: getRem(15);
          margin: 0;
          line-height: normal;
        }

        p {
          font-size: getRem(14);
        }
      }
    }

    &-collapsible {
      margin-top: getRem(20);
      margin-bottom: getRem(40);
      h2 {
        font-size: getRem(33);
        margin: 0;
        line-height: 0.8;
      }
    }
  }
}

.Collapsible__contentInner {
  padding: getRem(10);
  border: getRem(1) solid rgb(194, 194, 194);
  border-top: 0;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);

  p {
    margin-bottom: getRem(10);
    font-size: getRem(14);
    line-height: getRem(20);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.collapsible_trigger {
  display: flex;
  justify-content: space-between;

  .collapsible_icon {
    transition: transform 300ms;
  }
}

.Collapsible__trigger {
  font-size: getRem(14);
  display: block;
  justify-content: space-between;
  font-weight: 500;
  position: relative;
  border: getRem(1) solid #d1d5db;
  padding: getRem(16);
  margin-top: getRem(20);
  -webkit-box-shadow: getRem(0) getRem(0) getRem(5) getRem(-1) rgba(0, 0, 0, 0.75);
  -moz-box-shadow: getRem(0) getRem(0) getRem(5) getRem(-1) rgba(0, 0, 0, 0.75);
  box-shadow: getRem(0) getRem(0) getRem(5) getRem(-1) rgba(0, 0, 0, 0.75);

  &.is-open {
    .collapsible_icon {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.correlationContainer {
  margin: 0 auto;
  width: getRem(600);
  .correlationError {
    position: fixed;
    top: getRem(120);
  }
}
