@import '../../../styles/functions';

.packaging {
  // margin-left: 16px;
  padding: 16px;
  &-actions {
    position: relative;
    display: flex;
    gap: getRem(20);
    width: 100%;
  }
}
