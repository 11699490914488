@import '../../styles/functions';

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  left: 0;
  top: getRem(36);
  width: 100%;
  height: calc(100% - 2.25rem);
  background: white;
  button {
    margin-top: getRem(20);
  }
  h3 {
    font-size: getRem(36);
    margin-top: getRem(15);
  }
}
