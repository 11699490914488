@import '../../styles/functions';
@import '../../styles/vars';

.audit {
  padding: 0 getRem(15);
  background-color: $mondraBackgroundGrey;
  height: 100%;
  overflow: auto;

  &-classification {
    margin-top: getRem(18);

    h5 {
      font-size: getRem(18);
      margin-bottom: getRem(10);
    }
  }

  &-record {
    padding: getRem(16);
    border: getRem(1) solid #d1d5db;
    box-shadow: 0 getRem(1) getRem(3) rgba(0, 0, 0, 0.1), 0 getRem(1) getRem(2) rgba(0, 0, 0, 0.06);
    border-radius: getRem(8);
    background-color: #ffffff;
    margin-top: 16px;

    &-visible {
      display: flex;
      align-items: center;
      background: #f3f4f6;
      font-size: getRem(12);
      gap: getRem(5);
      border-radius: getRem(12);
      width: getRem(228);
      justify-content: center;
      margin-top: getRem(9);
      margin-bottom: getRem(16);
    }

    h5 {
      margin: 0;
      font-size: getRem(18);
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: getRem(10);
      font-size: getRem(12);
    }

    &-button {
      margin-top: getRem(15);
      padding: getRem(7) getRem(11);
      border: getRem(1) solid #d1d5db;
      border-radius: getRem(6);
      font-size: getRem(14);

      &:hover {
        background-color: rgb(229, 231, 235);
      }
    }
  }

  &-full {
    position: fixed;
    background-color: white;
    z-index: 600;
    height: 100%;
    right: 0;
    top: 0;
    width: getRem(800);
    overflow: auto;
    box-shadow: getRem(-4) 0 getRem(4) rgba(0, 0, 0, 0.15);

    p {
      padding: getRem(0) getRem(12) getRem(22) getRem(13);
      font-size: getRem(14);
      color: #6b7280;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: getRem(14) getRem(20) getRem(14) getRem(13);

      h5 {
        margin: 0;
        font-size: getRem(20);
      }
    }
  }

  &-item {
    padding: getRem(16) getRem(13);
    overflow-x: scroll;
    white-space: nowrap;

    h6 {
      font-size: getRem(12);
    }

    &-author {
      font-size: getRem(12);
      display: flex;
      align-items: center;
      gap: getRem(15);

      div {
        padding: getRem(4) getRem(9);
        border-radius: getRem(4);
        font-size: getRem(12);
        background-color: #d1d5db;
      }

      h5 {
        font-size: getRem(12);
        margin: 0;
      }

      span {
        color: #4b5563;
      }
    }

    &-from,
    &-to {
      font-size: getRem(10);
      display: flex;
      gap: getRem(8);

      div {
        padding: getRem(2) getRem(4);
        border-radius: getRem(3);
      }
    }

    &-from {
      div {
        background: #fecaca;
      }
    }

    &-to {
      div {
        background: #a7f3d0;
      }
    }
  }
}
