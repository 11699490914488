@import '../../styles/functions';

.inputItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  margin-top: getRem(20);
  h3 {
    font-size: getRem(15);
    margin: 0;
    line-height: normal;
  }

  p {
    font-size: getRem(14);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.inputItem-25 {
  width: 24%;
}

.formik-error {
  color: #e11d48;
  font-size: getRem(14);
}

#root > div > div.piece.content > form > div > div > div.w-full.flex.items-center > div {
  width: auto;
}
#root > div > div.piece.content > form > div > div > div.w-full.flex.items-center > div > div {
  width: auto;
  min-width: getRem(50);
  padding: 0 getRem(5);
}
