@import '../../styles/functions';

.menu {
  width: getRem(300);
  border: getRem(1) solid #d9e2ec;
  &-items {
    height: auto;
    padding-top: getRem(30);
    padding-bottom: getRem(20);
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      height: getRem(43);
      width: getRem(270);
      margin-left: getRem(4);
      padding-left: getRem(28);
      font-size: getRem(14);
      font-weight: 500;
      color: #627d98;

      svg {
        margin-right: getRem(15);
        color: #627d98;
      }

      &:hover {
        font-size: getRem(14);
        font-weight: 500;
        color: #192c3d;
        background: #f0fafa;
        border-radius: 0 getRem(8) getRem(8) 0;

        svg {
          color: #4b9b9b;
        }
      }
    }

    &-active {
      border-left: solid getRem(4) #4b9b9b;
      background: #f0fafa;
      padding-left: getRem(24);
      border-radius: 0px 8px 8px 0px;
      font-size: getRem(14);
      font-weight: 500;
      color: #192c3d;

      svg {
        color: #4b9b9b;
      }
    }
  }

  hr {
    margin: 0 getRem(14);
  }

  h4 {
    font-size: getRem(14);
    text-transform: uppercase;
    padding-left: getRem(19);
    color: #627d98;
  }

  &-tools {
    display: flex;
    flex-direction: column;

    &-item {
      display: inline-flex;
      padding-left: getRem(28);
      font-size: getRem(14);
      font-weight: 500;
      color: #627d98;
      padding-top: getRem(10);

      svg {
        margin-right: getRem(15);
        color: #627d98;
      }
    }
  }
}
