@tailwind base;
@tailwind components;
@tailwind utilities;

/* base */
@import 'functions';
@import 'vars';
@import 'breakpoints';

html,
body {
  font-size: 12px;
}

@media screen and (min-width: 1366px) {
  html,
  body {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  padding: getRem(16);
  border: getRem(1) solid #d1d5db;
  box-shadow: 0 getRem(1) getRem(3) rgba(0, 0, 0, 0.1), 0 getRem(1) getRem(2) rgba(0, 0, 0, 0.06);
  border-radius: getRem(8);
  background-color: #ffffff;
  margin-bottom: 16px;

  h4 {
    font-size: getRem(18);
  }

  h5 {
    font-size: getRem(15);
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: getRem(10);
    font-size: getRem(12);
  }
}
