@import '../../styles/functions';

.dashboard {
  &-information {
    padding: getRem(24);
    border: gteRem(1) solid #d1d5db;

    box-shadow: 0 getRem(1) getRem(3) rgba(0, 0, 0, 0.1),
      0 getRem(1) getRem(2) getRem(-1) rgba(0, 0, 0, 0.1);
    border-radius: getRem(8);
    &-icon {
      padding: getRem(11);
      border-radius: getRem(6);
      background: #f0faf9;
      width: fit-content;
    }
    &-flex {
      display: flex;
      justify-content: space-between;
    }
    &-main {
      width: getRem(300);
      height: fit-content;
      color: white;
      padding: getRem(24);
      background: #398789;
      border-radius: getRem(6);
    }
  }
}
