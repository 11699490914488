@import '../../styles/functions';

.notes {
  &-full {
    position: fixed;
    background-color: white;
    z-index: 600;
    height: 100%;
    right: 0;
    top: 0;
    width: getRem(400);
    overflow: auto;
    box-shadow: getRem(-4) 0 getRem(4) rgba(0, 0, 0, 0.15);

    p {
      padding: getRem(0) getRem(12) getRem(22) getRem(13);
      font-size: getRem(14);
      color: #6b7280;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: getRem(14) getRem(20) getRem(14) getRem(13);

      h5 {
        margin: 0;
        font-size: getRem(20);
      }
    }

    &-select {
      cursor: pointer;
      display: flex;
      gap: getRem(25);
      margin-left: getRem(13);

      &-item {
        padding-bottom: getRem(10);
        display: flex;
        gap: getRem(7);
        font-size: getRem(14);

        div {
          padding: 0 getRem(12);
          border-radius: getRem(12);
          font-size: getRem(12);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #374151;
        }
      }
    }

    &-input {
      display: flex;
      align-items: flex-end;
      position: relative;
      button {
        height: min-content;
        font-size: getRem(16);
        color: white;
        padding: getRem(7) getRem(12);
        background-color: #059669;
        &:disabled {
          opacity: 0.6;
        }
      }
    }

    &-textarea {
      resize: none;
      font-size: getRem(14);
      margin-left: getRem(13);
      margin-top: getRem(13);
      width: 80%;
      height: getRem(45);
      border: 0;
      border-bottom: getRem(1) solid gray;

      &:focus,
      &:active {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-bottom: getRem(2) solid #059669;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .error {
      position: absolute;
      bottom: getRem(-20);
      left: getRem(13);
      color: #e11d48;
      font-size: getRem(14);
    }
  }

  &-button {
    cursor: pointer;
    width: fit-content;
    display: flex;
    padding: getRem(3) getRem(6);
    align-items: center;
    gap: getRem(9);
    background: #6b7280;
    border-radius: getRem(2);
    color: white;
    font-size: getRem(12);

    svg {
      color: white;
      width: getRem(14);
      height: getRem(13);
    }
  }

  &-item {
    padding: getRem(16) getRem(18) getRem(0);

    p {
      margin-top: getRem(8);
      font-size: getRem(14);
      color: black;
    }

    &-header {
      display: flex;
      gap: getRem(8);

      span {
        color: #4b5563;
        font-size: getRem(12);
      }

      h5 {
        margin: 0;
        font-size: getRem(14);
        line-height: getRem(14);
        font-weight: bold;
      }

      &-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: getRem(24);
        height: getRem(24);
        font-size: getRem(12);
        border-radius: getRem(2);
        color: white;
        background: #059669;
      }
    }
  }
}

.notes-active {
  border-bottom: #059669 solid getRem(2);
  font-weight: bold;
}
